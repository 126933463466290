<template>
    <div class="nvx-app-container">
        <div class="loader-container">
            <div class="loader"> </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VerifyAuth",
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";
@import "@/assets/scss/appmenus/native.scss";
</style>
